import Vue from 'vue'
import VueRouter from 'vue-router'

import layout from '../views/layout'

// 引入组件
Vue.use(VueRouter)

export const asyncRoutes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/home.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/about',
                component: () => import('@/views/about/about.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/produce',
                component: () => import('@/views/produce/produce.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/news',
                component: () => import('@/views/news/news.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/message',
                component: () => import('@/views/message/message.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/relation',
                component: () => import('@/views/relation/relation.vue')
            }
        ]
    },
    {
        path: '',
        component: layout,
        children: [
            {
                path: '/baidu',
                name: 'baidu',
                component: () => import('@/views/BaiduView.vue')
            }
        ]
    },
]

const router = new VueRouter({
    routes: asyncRoutes
})


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export default new VueRouter({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: asyncRoutes
})
